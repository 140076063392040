import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import Heros from "../components/heros";
import FlexibleContent from "../components/flexible-content";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
    home: sanityHome {
      description
      featuredImage {
        ...SanityImage
        alt
      }
      heros {
        _key
	      image {
          ...SanityImage
	        alt
	      }
	      imageMobile {
          ...SanityImage
	        alt
	      }
        video
        videoUrl
	      title
	      blurb
	      link {
	        blank
	        text
	        url
	      }
	    }
      flexibleContent {
        ... on SanityFcContentBlock {
          _key
          _type
          alignRight
          _rawText
        }
        ... on SanityFcFeaturedProducts {
          _key
          _type
          fillerImage {
            ...SanityImage
            alt
          }
          fillAlignRight
          featuredProducts {
            _key
            product {
              title
              slug {
                current
              }
              description
              featuredImage {
                ...SanityImage
                alt
              }
              secondaryFeaturedImage {
                ...SanityImage
                alt
              }
            }
          }
        }
        ... on SanityFc2ColumnImage {
          _key
          _type
          paddedLeft
          paddedRight
          imageLeft {
            ...SanityImage
            alt
            caption
          }
          imageRight {
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcSlider {
          _key
          _type
          slides {
            _key
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcBensenEveryday {
          _key
          _type
          intro
          overrides {
            _key
            bensenEveryday {
              title
              slug {
                current
              }
              description
              featuredImage {
                ...SanityImage
                alt
              }
            }
          }
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const home = (data || {}).home;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <Seo
        title={site.title}
        description={home.description}
        image={home.featuredImage}
      />

      {home.heros && (<Heros items={home.heros} />)}
      {home.flexibleContent && (<FlexibleContent items={home.flexibleContent} />)}
    </Layout>
  );
};

export default IndexPage;
